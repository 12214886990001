<template>
  <section :aria-label="section.headline">
    <div
      :class="{ dark: section.dark_mode }"
      v-bind:style="{
        'background-image': 'url(' + section.backgroundImage + ') ',
      }"
      class="
        component
        position-relative
        c-schedule
        padding-y-24
        bp-768:padding-y-48
      "
    >
      <div class="row padding-x-12 position-relative z-index-3">
        <common-header
          :headline="section.headline"
          :sponsor="section.sponsor"
        />
      </div>
      <div class="c-schedule__content position-relative z-index-3">
        <div class="c-schedule__template-1">
          <div
            class="
              row
              padding-y-24
              display-flex
              justify-content-center
              padding-x-12
            "
          >
            <div class="s-common__filter c-schedule__filters">
              <button
                class="
                  s-common__filter-button
                  c-schedule__filter-button
                  font-size-18
                  line-height-125
                "
                :class="{ 'is-active': active_filter == 'results' }"
                @click="filter_games('results')"
              >
                <feather
                  class="margin-r-6"
                  size="16px"
                  type="file-text"
                ></feather>
                Results
              </button>
              <button
                class="
                  s-common__filter-button
                  c-schedule__filter-button
                  font-size-18
                  line-height-125
                "
                :class="{
                  'is-active': active_filter == 'upcoming',
                }"
                @click="filter_games('upcoming')"
              >
                <feather class="margin-r-6" size="16px" type="clock"></feather>
                Upcoming
              </button>
            </div>
          </div>
          <div v-show="active_filter == 'upcoming'" class="row">
            <template v-if="upcoming && upcoming.length === 0">
              <div
                class="flex-item-1"
                style="
                  padding: 24px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                There are no upcoming games.
              </div>
            </template>
            <swiper
              v-if="upcoming && upcoming.length > 0"
              class="
                c-schedule__list
                display-none
                flex-item-1
                width-100
                padding-6
                bp-768:display-flex
              "
              :options="swiperOption2"
              ref="swiperTop"
            >
              <swiper-slide
                class="
                  c-schedule__desktop c-schedule__list--item
                  display-flex
                  padding-x-6
                "
                v-for="event in upcoming"
                :key="event.id"
              >
                <div
                  class="
                    c-schedule__list--item-inside
                    align-items-center
                    flex-direction-column
                    position-relative
                    display-flex
                  "
                  :class="{ recent: event.game_status === 'O' }"
                >
                  <div
                    v-if="event.opponent_image != '/'"
                    class="c-schedule__list--item-logo"
                  >
                    <img
                      :src="event.opponent_image + '?width=90'"
                      :alt="event.opponent + ' ' + event.id"
                    />
                  </div>
                  <div
                    v-if="event.opponent_image === '/' || !event.opponent_image"
                    class="c-schedule__list--item-logo"
                  >
                    <img
                      src="/images/logos/site/site.png?width=90"
                      :alt="event.opponent + ' ' + event.id"
                    />
                  </div>
                  <div
                    class="
                      c-schedule__list--item-date
                      font-size-16
                      line-height-150
                    "
                  >
                    {{
                      event.date ? moment(event.date).format("MM.DD.YY") : "N/A"
                    }}
                  </div>
                  <div class="c-schedule__list--item-details text-align-center">
                    <div
                      class="
                        c-schedule__list--item-opponent
                        font-size-16 font-weight-700
                        line-height-150
                      "
                    >
                      {{ event.opponent }}
                    </div>
                    <div
                      v-if="event.game_location"
                      class="
                        c-schedule__list--item-location
                        font-size-14
                        line-height-125
                        font-weight-300
                      "
                    >
                      {{ event.game_location }}
                    </div>
                  </div>
                  <div
                    v-if="event.game_status === 'O'"
                    class="
                      c-schedule__list--item-score
                      text-align-center
                      font-weight-700 font-size-16
                      line-height-150
                      margin-t-auto
                    "
                  >
                    <span v-if="event.result_status === 'T'">TIE</span>
                    <span v-if="event.result_status === 'W'">WIN</span>
                    <span v-if="event.result_status === 'L'">LOSS</span>
                    <div
                      v-if="
                        event.result_team_score && event.result_opponent_score
                      "
                    >
                      {{
                        event.result_team_score +
                        "-" +
                        event.result_opponent_score
                      }}
                    </div>
                  </div>
                  <div
                    v-if="event.ticket_link && event.game_status != 'O'"
                    class="c-schedule__list--item-link margin-t-auto"
                  >
                    <a
                      :href="event.ticket_link"
                      class="
                        margin-t-24
                        s-common__button
                        display-block
                        c-schedule__button c-schedule__more
                        font-size-14
                        line-height-125
                        font-weight-700
                      "
                      >Buy Tickets</a
                    >
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div
              class="c-schedule__mobile width-100 d-block bp-768:display-none"
            >
              <div class="c-schedule__list flex-item-1 padding-6">
                <div
                  class="
                    c-schedule__list--item
                    flex-item-1
                    display-flex
                    padding-6
                  "
                  v-for="event in upcoming"
                  :key="event.id"
                >
                  <div
                    class="
                      c-schedule__list--item-inside
                      position-relative
                      display-flex
                    "
                    :class="{
                      recent: event.game_status === 'O',
                    }"
                  >
                    <div
                      v-if="event.opponent_image != '/'"
                      class="c-schedule__list--item-logo"
                    >
                      <img
                        :src="event.opponent_image + '?width=100'"
                        :alt="event.opponent + ' ' + event.id"
                      />
                    </div>
                    <div
                      v-if="
                        event.opponent_image === '/' || !event.opponent_image
                      "
                      class="c-schedule__list--item-logo"
                    >
                      <img
                        src="/images/logos/site/site.png?width=100"
                        :alt="event.opponent + ' ' + event.id"
                      />
                    </div>
                    <div class="c-schedule__list--item-info flex-item-1">
                      <div
                        class="
                          c-schedule__list--item-date
                          font-weight-700 font-size-21
                          line-height-125
                        "
                      >
                        {{
                          event.date
                            ? moment(event.date).format("MM.DD.YY")
                            : "N/A"
                        }}
                      </div>
                      <div class="c-schedule__list--item-details">
                        <div
                          class="
                            c-schedule__list--item-opponent
                            font-size-16
                            line-height-150
                            font-weight-700
                          "
                        >
                          {{ event.opponent }}
                        </div>
                        <div
                          class="
                            c-schedule__list--item-location
                            font-size-16
                            line-height-150
                          "
                        >
                          {{ event.location }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="event.ticket_link && event.game_status != 'O'"
                      class="c-schedule__list--item-link"
                    >
                      <a
                        :href="event.ticket_link"
                        class="
                          s-common__button
                          c-schedule__button c-schedule__more
                          font-size-14
                          line-height-125
                          font-weight-700
                        "
                        >Buy Tickets</a
                      >
                    </div>
                    <div
                      v-if="event.game_status === 'O'"
                      class="
                        c-schedule__list--item-score
                        text-align-center
                        font-weight-700 font-size-14
                        line-height-125
                      "
                    >
                      <span v-if="event.result_status === 'T'">TIE</span>
                      <span v-if="event.result_status === 'W'">WIN</span>
                      <span v-if="event.result_status === 'L'">LOSS</span>
                      <div
                        v-if="
                          event.result_team_score && event.result_opponent_score
                        "
                      >
                        {{
                          event.result_team_score +
                          "-" +
                          event.result_opponent_score
                        }}
                      </div>
                    </div>
                    <div
                      :class="
                        'c-schedule__list--item-indicator ' +
                        event.location_indicator
                      "
                    ></div>
                    <div
                      v-if="event.tickets_link && event.game_status != 'O'"
                      class="c-schedule__list--item-link"
                    >
                      <a
                        :href="event.tickets_link"
                        class="
                          s-common__button
                          c-schedule__button c-schedule__more
                          font-size-14
                          line-height-125
                          font-weight-700
                        "
                        >Buy Tickets</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                width-100
                padding-t-24
                swiper-arrows
                display-flex
                justify-content-center
              "
            >
              <div
                v-if="upcoming && upcoming.length > 0"
                class="
                  swiper-button-prev2 swiper-button-white
                  c-common__arrow-alt c-common__arrow
                  display-none
                  bp-768:display-flex
                "
                slot="button-prev"
              >
                <feather size="24px" type="arrow-left"></feather>
              </div>
              <a
                class="
                  s-common__button s-common__button-large
                  c-schedule__more
                  text-align-center
                  margin-0
                  font-size-14
                  line-height-125
                  font-weight-700
                  display-flex
                "
                :href="
                  '/sports/' + this.$root.gameday.sport.shortname + '/schedule/'
                "
              >
                <feather
                  class="margin-r-6"
                  size="16px"
                  type="calendar"
                ></feather>
                Full Schedule</a
              >
              <div
                v-if="upcoming && upcoming.length > 0"
                class="
                  swiper-button-next2 swiper-button-white
                  display-none
                  bp-768:display-flex
                  c-common__arrow-alt c-common__arrow
                "
                slot="button-next"
              >
                <feather size="24px" type="arrow-right"></feather>
              </div>
            </div>
          </div>
          <div v-show="active_filter === 'results'" class="row">
            <template v-if="results && results.length === 0">
              <div
                class="flex-item-1"
                style="
                  padding: 24px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                There are no recent games.
              </div>
            </template>
            <swiper
              v-if="results && results.length > 0"
              class="
                c-schedule__list
                display-none
                flex-item-1
                width-100
                padding-6
                bp-768:display-flex
              "
              :options="swiperOption"
              ref="swiperTop"
            >
              <swiper-slide
                class="
                  c-schedule__desktop c-schedule__list--item
                  display-flex
                  padding-x-6
                "
                v-for="event in results"
                :key="event.id"
              >
                <div
                  class="
                    c-schedule__list--item-inside
                    align-items-center
                    flex-direction-column
                    position-relative
                    display-flex
                  "
                  :class="{ recent: event.game_status === 'O' }"
                >
                  <div
                    v-if="event.opponent_image != '/'"
                    class="c-schedule__list--item-logo"
                  >
                    <img
                      :src="event.opponent_image + '?width=90'"
                      :alt="event.opponent + ' ' + event.id"
                    />
                  </div>
                  <div
                    v-if="event.opponent_image === '/' || !event.opponent_image"
                    class="c-schedule__list--item-logo"
                  >
                    <img
                      src="/images/logos/site/site.png?width=90"
                      :alt="event.opponent + ' ' + event.id"
                    />
                  </div>
                  <div
                    class="
                      c-schedule__list--item-date
                      font-size-16
                      line-height-150
                    "
                  >
                    {{
                      event.date ? moment(event.date).format("MM.DD.YY") : "N/A"
                    }}
                  </div>
                  <div class="c-schedule__list--item-details text-align-center">
                    <div
                      class="
                        c-schedule__list--item-opponent
                        font-size-16 font-weight-700
                        line-height-150
                      "
                    >
                      {{ event.opponent }}
                    </div>
                    <div
                      class="
                        c-schedule__list--item-location
                        font-size-14
                        line-height-125
                        font-weight-300
                      "
                    >
                      {{ event.location }}
                    </div>
                  </div>
                  <div
                    v-if="event.game_status === 'O'"
                    class="
                      c-schedule__list--item-score
                      text-align-center
                      font-weight-700 font-size-16
                      line-height-150
                      margin-t-auto
                    "
                  >
                    <span v-if="event.result_status === 'T'">TIE</span>
                    <span v-if="event.result_status === 'W'">WIN</span>
                    <span v-if="event.result_status === 'L'">LOSS</span>
                    <div
                      v-if="
                        event.result_team_score && event.result_opponent_score
                      "
                    >
                      {{
                        event.result_team_score +
                        "-" +
                        event.result_opponent_score
                      }}
                    </div>
                  </div>

                  <div
                    v-if="event.tickets_link && event.game_status != 'O'"
                    class="c-schedule__list--item-link"
                  >
                    <a
                      :href="event.tickets_link"
                      class="
                        s-common__button
                        c-schedule__button c-schedule__more
                        font-size-14
                        line-height-125
                        font-weight-700
                      "
                      >Buy Tickets</a
                    >
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div
              class="c-schedule__mobile width-100 d-block bp-768:display-none"
            >
              <div class="c-schedule__list flex-item-1 padding-6">
                <div
                  class="
                    c-schedule__list--item
                    flex-item-1
                    display-flex
                    padding-6
                  "
                  v-for="event in results"
                  :key="event.id"
                >
                  <div
                    class="
                      c-schedule__list--item-inside
                      position-relative
                      display-flex
                    "
                    :class="{
                      recent: event.game_status === 'O',
                    }"
                  >
                    <div
                      v-if="event.opponent_image != '/'"
                      class="c-schedule__list--item-logo"
                    >
                      <img
                        :src="event.opponent_image + '?width=100'"
                        :alt="event.opponent + ' ' + event.id"
                      />
                    </div>
                    <div
                      v-if="
                        event.opponent_image === '/' || !event.opponent_image
                      "
                      class="c-schedule__list--item-logo"
                    >
                      <img
                        src="/images/logos/site/site.png?width=100"
                        :alt="event.opponent + ' ' + event.id"
                      />
                    </div>
                    <div class="c-schedule__list--item-info flex-item-1">
                      <div
                        class="
                          c-schedule__list--item-date
                          font-weight-700 font-size-21
                          line-height-125
                        "
                      >
                        {{
                          event.date
                            ? moment(event.date).format("MM.DD.YY")
                            : "N/A"
                        }}
                      </div>
                      <div class="c-schedule__list--item-details">
                        <div
                          class="
                            c-schedule__list--item-opponent
                            font-size-16
                            line-height-150
                            font-weight-700
                          "
                        >
                          {{ event.opponent }}
                        </div>
                        <div
                          class="
                            c-schedule__list--item-location
                            font-size-16
                            line-height-150
                          "
                        >
                          {{ event.location }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="event.game_status === 'O'"
                      class="
                        c-schedule__list--item-score
                        text-align-center
                        font-weight-700 font-size-14
                        line-height-125
                      "
                    >
                      <span v-if="event.result_status === 'T'">TIE</span>
                      <span v-if="event.result_status === 'W'">WIN</span>
                      <span v-if="event.result_status === 'L'">LOSS</span>
                      <div
                        v-if="
                          event.result_team_score && event.result_opponent_score
                        "
                      >
                        {{
                          event.result_team_score +
                          "-" +
                          event.result_opponent_score
                        }}
                      </div>
                    </div>
                    <div
                      :class="
                        'c-schedule__list--item-indicator ' +
                        event.location_indicator
                      "
                    ></div>
                    <div
                      v-if="event.tickets_link && event.game_status != 'O'"
                      class="c-schedule__list--item-link"
                    >
                      <a
                        :href="event.tickets_link"
                        class="
                          s-common__button
                          c-schedule__button c-schedule__more
                          font-size-14
                          line-height-125
                          font-weight-700
                        "
                        >Buy Tickets</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                width-100
                padding-t-24
                swiper-arrows
                display-flex
                justify-content-center
              "
            >
              <div
                v-if="results && results.length > 0"
                class="
                  swiper-button-prev swiper-button-white
                  c-common__arrow-alt c-common__arrow
                  display-none
                  bp-768:display-flex
                "
                slot="button-prev"
              >
                <feather size="24px" type="arrow-left"></feather>
              </div>
              <a
                class="
                  s-common__button s-common__button-large
                  c-schedule__more
                  text-align-center
                  margin-0
                  font-size-14
                  line-height-125
                  font-weight-700
                  display-flex
                "
                :href="
                  '/sports/' + this.$root.gameday.sport.shortname + '/schedule/'
                "
              >
                <feather
                  class="margin-r-6"
                  size="16px"
                  type="calendar"
                ></feather>
                Full Schedule</a
              >
              <div
                v-if="results && results.length > 0"
                class="
                  swiper-button-next swiper-button-white
                  c-common__arrow-alt c-common__arrow
                  display-none
                  bp-768:display-flex
                "
                slot="button-next"
              >
                <feather size="24px" type="arrow-right"></feather>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionBase from "./SectionBase";
import CommonHeader from "@/components/common/CommonHeader.vue";
export default {
  extends: SectionBase,
  name: "SectionSchedule",
  components: {
    CommonHeader,
  },
  data: function () {
    return {
      active_filter: "upcoming",
      swiperOption: {
        slidesPerView: 6,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1561: {
            slidesPerView: 6,
          },
          1281: {
            slidesPerView: 5,
          },
          1025: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          0: {
            slidesPerView: 1,
          },
        },
      },
      swiperOption2: {
        slidesPerView: 6,
        navigation: {
          nextEl: ".swiper-button-next2",
          prevEl: ".swiper-button-prev2",
        },
        breakpoints: {
          1561: {
            slidesPerView: 6,
          },
          1281: {
            slidesPerView: 5,
          },
          1025: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          0: {
            slidesPerView: 1,
          },
        },
      },
    };
  },
  methods: {
    filter_games(filter) {
      this.active_filter = filter;
    },
    set_default_filter() {
      if (this.upcoming && this.upcoming.length > 0) {
        this.active_filter = "upcoming";
      } else {
        this.active_filter = "results";
      }
    },
  },
  computed: {
    upcoming() {
      if (
        this.section &&
        this.section.scheduleGames &&
        this.section.scheduleGames.length
      ) {
        return this.section.scheduleGames.filter((game) => {
          return game.game_status === "A";
        });
      } else {
        return null;
      }
    },
    results() {
      if (
        this.section &&
        this.section.scheduleGames &&
        this.section.scheduleGames.length
      ) {
        return this.section.scheduleGames.filter((game) => {
          return game.game_status != "A";
        });
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.set_default_filter();
  },
};
</script>

<style lang="scss">
.swiper-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.swiper-button-prev,
.swiper-button-next,
.swiper-button-prev2,
.swiper-button-next2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  height: 48px;
  width: 48px;
  border: 1px solid $main-font-color;
  margin: 0 12px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  &:after {
    content: none;
  }
  &:after {
    font-size: 24px;
    color: $main-font-color;
  }
  &.swiper-button-disabled {
    display: flex;
    opacity: 0;
    pointer-events: none;
  }
}
</style>
<style scoped lang="scss">
.c-schedule {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: $main-color;
    opacity: 0.7;
    z-index: 2;
  }
}
.c-schedule__template-1 {
  .c-schedule {
    &__button {
      min-height: 35px;
    }
    &__list {
      &--item {
        &-inside {
          color: $main-font-color;
          background: $main-color;
          border: 1px solid $main-border-color;
          box-sizing: border-box;
          border-radius: 10px;
          min-height: 400px;
          display: flex;
          flex: 1;
          padding: 24px;
        }
        &-date {
          margin: 0 0 24px 0;
        }
        &-details {
          margin: 0 0 24px 0;
          flex: 1;
          align-items: flex-start;
        }
        &-indicator {
          position: absolute;
          bottom: 27px;
          left: 50%;
          transform: translateX(-50%);
          width: 60px;
          height: 3px;
          background: var(--color-primary);
          &.A {
            background: $color-safe-text;
          }
          &.N {
            background: #c3c0c6;
          }
        }
        &-logo {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 0 24px 0;
        }
      }
    }
    &__mobile {
      .c-schedule {
        &__button {
          width: 80px;
          height: 50px;
          font-size: 12px;
          margin: 0;
        }
        &__list {
          &--item {
            &-logo {
              width: 75px;
              height: 75px;
              border-radius: 100%;
              background: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                max-width: 50px;
              }
              margin: 0;
            }
            &-info {
              padding: 24px 12px 24px 12px;
            }
            &-inside {
              align-items: center;
              min-height: auto;
              padding: 12px 12px 15px 12px;
            }
            &-indicator {
              bottom: 12px;
            }
            &-score {
              margin: 0 12px;
            }
            &-date {
              margin-bottom: 12px;
            }
            &-details {
              margin: 0;
              display: block;
            }
          }
        }
      }
    }
  }
}
.c-schedule__template-2 {
  .c-schedule {
    &__list {
      margin: 0;
      list-style: none;
      padding: 0;
    }
    &__more {
    }
    &__key {
      margin: 24px 0 0 0;
      ul {
        li {
          color: $main-font-color;
          margin: 0 12px;
          font-size: 10px;
          line-height: 150%;
          font-weight: bold;
          text-align: center;
          text-transform: uppercase;
          &:before {
            content: "";
            display: block;
            width: 60px;
            margin: 0 0 6px 0;
            height: 3px;
            background: var(--color-primary);
          }
          &.R {
            &:before {
              background: #eeeeee;
            }
          }
        }
      }
    }
    &__item {
      border: 1px solid $main-border-color;
      border-radius: 10px;
      margin: 12px 0 0 0;
      background: $main-color;
      color: $main-font-color;
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
      padding: 48px;
      &.recent {
        background: #eeeeee;
      }
      &--logos {
        flex-shrink: 0;
        padding-right: 48px;
        border-right: 1px dashed $main-border-color;
      }
      &--logo {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--dot {
        height: 4px;
        width: 4px;
        display: block;
        background: $main-border-color;
        border-radius: 50%;
        overflow: hidden;
      }
      &--details {
        padding: 0 48px;
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      &--link {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        padding: 0 0 0 48px;
        border-left: 1px dashed $main-border-color;
        a {
          width: 123px;
          height: 48px;
          background: $color-primary;
          color: $text-on-primary;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &--vsat {
        flex-shrink: 0;
        border: 1px solid $main-border-color;
        border-radius: 50%;
        display: flex;
        background: #fff;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        color: $color-safe-text;
      }
    }
    @media screen and (max-width: 1279px) {
      &__item {
        &--vsat {
          margin: 0 12px !important;
        }
        &--logo {
          width: 50px;
          height: 50px;
        }
      }
    }
    @media screen and (max-width: 1023px) {
      &__item {
        padding: 24px;
        &--details {
          padding: 0 24px;
        }
        &--link {
          padding-left: 24px;
        }
        &--logos {
          padding-right: 24px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      &__item {
        display: block !important;
        padding: 0;
        &--details {
          padding: 24px;
          text-align: center;
          align-items: center;
        }
        &--link {
          border: none;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 0 24px 24px 24px;
        }
        &--logos {
          border: none;
          display: flex;
          padding: 0;
          position: relative;
          border-bottom: 1px solid $main-border-color;
        }
        &--vsat {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -15px -15px !important;
          z-index: 2;
        }
        &--logo {
          padding: 24px;
          flex: 0 1 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: auto;
          &:first-child {
            border-right: 1px solid $main-border-color;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
[data-theme="dark"] {
  .s-home > .component:nth-of-type(odd) {
    .c-schedule__template-1 {
      .c-schedule__list {
        .c-schedule__list--item {
          .c-schedule__list--item-inside {
            background: #151515;
            &.recent {
              background: #0d0d0d;
            }
          }
        }
      }
    }
    .c-schedule__template-2 {
      .c-schedule {
        &__item {
          background: #151515;
          &.recent {
            background: #0d0d0d;
          }
          &--vsat {
            background: #151515;
            color: #fff;
          }
        }
      }
    }
  }
  .c-schedule__template-1 {
    .c-schedule {
      &__key {
        ul {
          li {
            &.A {
              &:before {
                background: $color-safe-text;
              }
            }
          }
        }
      }
      &__list {
        &--item {
          &-indicator {
            &.A {
              background: $color-safe-text;
            }
          }
          &-inside {
            &.recent {
              background: #0d0d0d;
            }
          }
        }
      }
    }
  }
  .c-schedule__template-2 {
    .c-schedule {
      &__item {
        &.recent {
          background: #0d0d0d;
        }
        &--vsat {
          background: #151515;
          color: #fff;
        }
      }
    }
  }
}
</style>
